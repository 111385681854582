export const scorecardRoutes = [
    {
        path: '/scorecard',
        name: 'scorecard',
        meta: {
            requiresAuth: true,
            accessId: 'scorecard',
        },
        component: () =>
            import ('../views/Scorecard.vue')
    },
    {
        path: '/scorecard/:subjectId',
        name: 'scorecard-details',
        meta: {
            requiresAuth: true,
            accessId: 'scorecard',
        },
        component: () =>
            import ('../views/Details.vue')
    },
]
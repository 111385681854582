export const rewardRoutes = [
    {
        path: '/reward',
        name: 'reward',
        meta: {
            requiresAuth: true,
            accessId: 'reward',
        },
        component: () =>
            import ('../views/Reward.vue')
    },
    {
        path: '/reward/view/:userId',
        name: 'review',
        meta: {
            requiresAuth: true,
            accessId: 'reward',
        },
        component: () =>
            import ('../views/View.vue')
    }
]
import Scheduler from '../views/Scheduler.vue';
import Announcements from '../views/components/schedulers/Announcements.vue';
import AnnouncementCreate from '../views/components/schedulers/AnnouncementCreate.vue'
import Tasks from '../views/components/schedulers/Tasks.vue';

export const schedulerRoutes = [{
    path: '/scheduler',
    name: 'Scheduler',
    meta: {
        requiresAuth: true,
        accessId: 'scheduler',
    },
    component: Scheduler,
    children: [{
            path: 'announcements',
            component: Announcements
        },
        {
            path: 'announcements/create',
            component: AnnouncementCreate
        },
        {
            path: 'tasks',
            component: Tasks
        },
    ],
    meta: {
        requiresAuth: true
    }
}, ]
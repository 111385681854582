export const plusSurveyRoutes = [{
        path: '/plus-survey',
        name: 'plusSurvey',
        meta: {
            requiresAuth: true,
            accessId: 'plus_survey',
        },
        component: () =>
            import ('../views/PlusSurveyLanding.vue')
    },
    {
        path: '/plus-survey/:subjectId',
        name: 'plusSurveySubjectDetails',
        meta: {
            requiresAuth: true,
            accessId: 'plus_survey',
        },
        component: () =>
            import ('../views/PlusSurveySubjectDetails.vue')
    },
    {
        path: '/plus-survey/:subjectId/user/:userId',
        name: 'plusSurveyUserDetails',
        meta: {
            requiresAuth: true,
            accessId: 'plus_survey',
        },
        component: () =>
            import ('../views/PlusSurveyUserDetails.vue')
    },
]